.photo_container {
    display: inline;
}

.photo_add_container {
    display: inline;
}

.photo_holder {
    display: inline;
}

.photo_frame {
    width: 80px;
    height: 80px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    /* display: inline; */
    margin-right: 10px;
    cursor: pointer;
}

.photo_add {
    cursor: pointer;
}

.photo_remove_btn {
    position: absolute;
    margin-left: -30px;
    margin-top: -5px;
    border: 1px solid #e0e0e0 !important;
    border-radius: 50px;
    padding: 1px 0px 0px 0px !important;
    background-color: white !important;
    color: #e0e0e0;
    opacity: 1;
    width: 22px;
}