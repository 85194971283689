.no-action {
    pointer-events: none !important;
}

.checkbox {
    display: block !important;
    box-shadow: none;
}

.d-flex {
    display: flex !important;
}

.flex-row {
    flex-direction: row !important;
}

.flex-column {
    flex-direction: column !important;
}

.align-items-start {
    align-items: flex-start !important;
}

.align-items-end {
    align-items: flex-end !important;
}

.align-items-center {
    align-items: center !important;
}

.justify-content-start {
    justify-content: flex-start !important;
}

.justify-content-end {
    justify-content: flex-end !important;
}

.justify-content-center {
    justify-content: center !important;
}

.justify-content-between {
    justify-content: space-between !important;
}

.px-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.px-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
}